import React , {useEffect, useState} from 'react'
import "./profil.css"
import { Link } from 'react-router-dom'
import Footer from "../../Footer"
import CookieAlert from "../../PUBLIC/CookieAlert"
import Header from "../HEADER/header"
import { useSelector, useDispatch } from "react-redux"
import { addProfilJson, addUserOutfitsJson } from "../../../redux/actions"
import {createImageUrl} from "../../HELPER/helper"


function Profil(props) {

	// Outfits
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState("")
	const [outfits, setOutfits] = useState([])

	// User
	const [user, setUser] = useState({})

	// Redux
	const dispatch = useDispatch()
	const savedProfil = useSelector(state => state.profilApi)
	const savedUserOutfits = useSelector(state => state.userOutfits)


	useEffect(() => {
		document.title = `Infinit Tag - ${props.match.params.username}`

		if (savedProfil === 0) { 
			fetchUser() 
			console.log("fetch")
		} else if (savedProfil.username === props.match.params.username) {
			console.log("use saved profil")
			setUser(savedProfil)
			setOutfits(savedUserOutfits)
		} else {
			console.log("wrong user")
			fetchUser()
		}
	}, [])

 
	const fetchUser = async () => {
		setIsLoading(true)
		setError("")

		let response = await fetch(`https://infinit-tag-api.herokuapp.com/api/user/infos/username/name=${props.match.params.username}`, {
			method: 'GET'
		})
		// let response = await fetch(`http://localhost:3000/api/user/infos/username/name=${props.match.params.username}`, {
		// 	method: 'GET'
		// })

		if (response.ok) {
			let json = await response.json()
			document.title = `${json.name} (@${json.username}) - Infinit Tag`
			setUser(json)
			fetchOutfits(json._id)
			dispatch(addProfilJson(json))

		} else {
			let json = await response.json()
			console.log("error", json)
			setError("HTTP-Error: " + response.status)
		}
	}


	const fetchOutfits = async (userId) => {
		let response = await fetch(`https://infinit-tag-api.herokuapp.com/api/user-outfits/approved/${userId}`, {
			method: 'GET'
		})
		// let response = await fetch(`http://localhost:3000/api/user-outfits/approved/${userId}`, {
		// 	method: 'GET'
		// })

		if (response.ok) {
			let json = await response.json()
			setOutfits(json)
			dispatch(addUserOutfitsJson(json))

		} else {
			let json = await response.json()
			console.log(json)
			// props.history.push("/404")
			setError("HTTP-Error: " + response.status)
		}

		setIsLoading(false)
	}


	// Header infp
	const headerInfo = {
		profil: props.match.params.username
	}


	// Image Collection
	const setOutfitsColl = () => {
		return outfits.map(outfit => 
			<Link className="img-container" key={outfit._id} to={{
				pathname: `/profil/${props.match.params.username}/${outfit._id}`,
				state: {
					outfit: outfit
				}
			}}>
				{}
				<img className="outfit-image" alt={"Outfit by " + outfit.username + " " + outfit.release} src={createImageUrl(outfit.imageUrl, 600, undefined, 65)}></img>
			</Link>
		)
	}

	return(
		<div className="Profil">
			<Header data={headerInfo}/>

			<div className="info-wrapper">
				<div className="profil-infos-container">
					<div className="profil-image-container">
						<img className="profil-image" src={createImageUrl(user.profilImageUrl, 400 * window.devicePixelRatio, undefined, 80)}></img>
					</div>

					<div className="infos">
						<p className="name">{user.name}</p>
						<p className="username">{user.username}</p>
						<div className="follower-container">
							<p className="follower">{user.follower !== undefined ? user.follower.length : ""}</p>
							<p className="follower-title">FOLLOWER</p>
						</div>
						{ user.bio !== undefined ? <p className="bio">{user.bio}</p> : ""}
					</div>

					<div className="shine"></div>
				</div>
			</div>

			<p className="outfits-title">OUTFITS</p>

			<div className="outfit-wrapper">
				<div className="outfits-container">
					{setOutfitsColl()}
				</div>
			</div>

			<Footer isSimilareItems={true}/>

			<CookieAlert/>
		</div>
	)
}

export default Profil