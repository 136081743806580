import React, {useEffect} from 'react';
import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Home from "./components/HOME/Home"
import StyleCollection from "./components/STYLE_COLLECTION/StyleCollection"
import StylePresentation from './components/STYLE_PRESANTAION/StylePresentation';
import Contact from './components/CONTACT/Contact'
import SimilarItems from "./components/SIMILAR_ITEMS/SimilarItems"
import Page404 from "./components/404/404"
import Impressum from "./components/IMPRESSUM/Impressum"
import Datenschutz from "./components/DATENSCHUTZ/Datenschutz"
// import PartnerProgram from "./components/PARTNER_PROGRAM/PartnerProgram"
import Payout from "./components/PAYOUT/Payout"
import PayoutRedirect from "./components/PAYOUT/RedirectPaypal"
import Profil from "./components/V2/PROFIL/profil"
import ResetPassword from "./components/RESET_PASSWORD/ResetPassword"
import Terms from "./components/TERMS/terms"
import firebase from "firebase/app"
import 'firebase/analytics';

function App() {


  useEffect(() => {
    // Your web app's Firebase configuration
    const firebaseConfig = {
      apiKey: "AIzaSyDYImBQVYp0vB52-8xid9Z5CkedJhxNepw",
      authDomain: "fashionapp-315e2.firebaseapp.com",
      databaseURL: "https://fashionapp-315e2.firebaseio.com",
      projectId: "fashionapp-315e2",
      storageBucket: "fashionapp-315e2.appspot.com",
      messagingSenderId: "411946703419",
      appId: "1:411946703419:web:d8e53fcc230c9627ffda1e",
      measurementId: "G-4JXSK1L5V6"
    };

    const userAcceptStats = getCookie("userAcceptStats")
		if (userAcceptStats) {
			if (userAcceptStats === "true") { 
        // Initialize Firebase
        firebase.initializeApp(firebaseConfig);
        firebase.analytics();
        // more at https://www.youtube.com/watch?v=iVHRy_uVtm0&t=197s

       } 
		} 
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])




  // Get Cookie
	const getCookie = (cname) => {
		var name = cname + "=";
		var ca = document.cookie.split(';');
		for(var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) === ' ') {
			c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
			}
		}
		return "";
	}



  return (
    <Router>
      {/* <div> */}
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/men" exact component={() => StyleCollection("men")} />
          <Route path="/women" exact component={() => StyleCollection("women")} />
          {/* <Route path="/:gender/:id" exact component={StylePresentation} /> */}
          {/* <Route path="/:gender/:id/similar-items-:itemId" exact component={SimilarItems} /> */}
          <Route path="/contact" exact component={Contact} />
          <Route path="/impressum" exact component={Impressum} />
          <Route path="/datenschutz" exact component={Datenschutz} />
          {/* <Route path="/partner-program" exact component={PartnerProgram} /> */}
          <Route path="/payout/paypal/redirect" exact component={PayoutRedirect} />
          <Route path="/payout/paypal/last-step/" exact component={Payout} />
          <Route path="/profil/:username" exact component={Profil} />
          <Route path="/profil/:username/:outfitId" exact component={StylePresentation} />
          <Route path="/reset-password/:userId/:token" exact component={ResetPassword} />
          <Route path="/terms" exact component={Terms} />
          <Route path="*" component={Page404} />
        </Switch>
        {/* <Footer />   */}
      {/* </div> */}
    </Router>
  )
}

export default App;
