import React, {useEffect, useState} from 'react'
import "./CookieAlert.css"
import firebase from "firebase/app"
import 'firebase/analytics'
import { getCookie } from '../helper'


function CookieAlert(props) {

	const [isInInduvidull, setIsInInduvidull] = useState(false)
	const [height, setHeight] = useState(0)
	const [isStatsChecked, setIsStatsChecked] = useState(false)
	const [shouldHide, setShouldHide] = useState(true)

	const refStart = React.createRef(null)
	const refInduviduell = React.createRef(null)

	useEffect(() => {
		console.log(height)
		if (isInInduvidull) {
			setHeight(refInduviduell.current.clientHeight + 63)
		} else {
			setHeight(refStart.current.clientHeight + 63)
		}
	}, [isInInduvidull, refStart, refInduviduell, height])


	useEffect(() => {
		const userAcceptStats = getCookie("userAcceptStats")
		if (userAcceptStats) {
			if (userAcceptStats === "true") { }
			setShouldHide(true)
		} else {
			setShouldHide(false)
		}
	}, [])

	// COOKIES
	const setCookie = (cname, cvalue, exdays) => {
		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		var expires = "expires="+d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}
	  

	// ON CLICK
	// Accept
	const onAccept = () => {
		setCookie("userAcceptStats", "true", 365)
		setShouldHide(true)
		configeFirebase()
	}

	// Induviduell
	const onInduvidullClick = () => {
		setIsInInduvidull(true)
	}

	// Accept all
	const onAcceptAll = () => {
		setCookie("userAcceptStats", "true", 365)
		setIsStatsChecked(true)
		setShouldHide(true)
		configeFirebase()
	}

	const onSaveClick = () => {
		setCookie("userAcceptStats", `${isStatsChecked}`, 365)
		setShouldHide(true)
	}

	const configeFirebase = () => {
		// Your web app's Firebase configuration
		const firebaseConfig = {
			apiKey: "AIzaSyDYImBQVYp0vB52-8xid9Z5CkedJhxNepw",
			authDomain: "fashionapp-315e2.firebaseapp.com",
			databaseURL: "https://fashionapp-315e2.firebaseio.com",
			projectId: "fashionapp-315e2",
			storageBucket: "fashionapp-315e2.appspot.com",
			messagingSenderId: "411946703419",
			appId: "1:411946703419:web:d8e53fcc230c9627ffda1e",
			measurementId: "G-4JXSK1L5V6"
		};
		
		// Initialize Firebase
		firebase.initializeApp(firebaseConfig);
		firebase.analytics();
		// more at https://www.youtube.com/watch?v=iVHRy_uVtm0&t=197s
	}

	// UI
	// Start Alert
	const startAlert = () => {
		return (
			<div ref={refStart}>
				{/* <p className="contentText">Wir nutzen auf unserer Webseite personenbezogene Daten, um dein Nutzererlebnis deutlich zu verbessern. Einige sind essenziell, andere helfen uns, die Inhalte unserer Seite zu optimieren.</p> */}
				<p className="contentText">We use personal data on our website to significantly improve your user experience. Some are essential, others help us to optimize the content of our website.</p>
				{/* <button className="acceptButton" onClick={onAccept}>EINVERSTANDEN</button> */}
				<button className="acceptButton" onClick={onAccept}>ACCEPT</button>
				<button className="individuellButton" onClick={onInduvidullClick}>INDIVIDUAL</button>
			</div>
		)
	}

	// Induviduell Alert
	const induviduellAlert = () => {
		return (
			<div ref={refInduviduell}>
				<button className="checkbox">
					<div className="circle" style={{backgroundColor: "#282828"}}></div>
					{/* <p className="title" style={{color: "#919191"}}>ESSENZIELL</p> */}
					<p className="title" style={{color: "#919191"}}>ESSENTIAL</p>
				</button>
				{/* <p className="contentInduviduellText">Essenzielle Cookies ermöglichen grundlegende Funktionen und sind für die einwandfreie Funktion der Website dringend erforderlich.</p> */}
				<p className="contentInduviduellText">Essential cookies enable basic functions and are essential for the proper functioning of the website.</p>
				
				<button className="checkbox" onClick={() => setIsStatsChecked(!isStatsChecked)}>
					<div className="circle" style={{backgroundColor: isStatsChecked ? "#282828" : ""}}></div>
					{/* <p className="title">STATISTIKEN</p> */}
					<p className="title">STATISTICS</p>
				</button>
				{/* <p className="contentInduviduellText">Diese Cookies erfassen anonyme Statistiken. Diese Informationen helfen uns zu verstehen, wie wir unsere Website noch weiter optimieren können.</p> */}
				<p className="contentInduviduellText">These cookies collect anonymous statistics. This information helps us to understand how we can further optimize our website.</p>
				
				<button className="saveButton" onClick={onSaveClick}>SAVE</button>
				{/* <button className="acceptAllButton" onClick={onAcceptAll}>ALLES AKZEPTIEREN</button> */}
				<button className="acceptAllButton" onClick={onAcceptAll}>ACCEPT ALL</button>
			</div>
		)
	}

	return(
		// <div className="wrapper">
			// {/* <div className="CookieAlert" style={{maxHeight: `${height}px`}}> */}
			<div className="CookieAlert" style={{maxHeight: `auto`, display: shouldHide ? "none" : ""}}>
				<p className="title">COOKIE SETTING</p>
				{isInInduvidull ? induviduellAlert() : startAlert()}	
			</div>
		// </div>
	)
}

export default CookieAlert