
// Sort to Newest / Default
export const sortNewest = (collection) => {
	collection.sort((styleA, styleB) => {
		return styleB.styleID - styleA.styleID
	})

	return collection
}

// 
export const pickRightStyle = (collection, id) => {
	let rightStyle
	for (const style of collection) {
	  if (id === String(style.styleID)) {
		rightStyle = style
		break
	  }
	}
	return rightStyle
}


export const getCookie = (cname) => {
	var name = cname + "=";
	var ca = document.cookie.split(';');
	for(var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === ' ') {
		c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
		return c.substring(name.length, c.length);
		}
	}
	return "";
}