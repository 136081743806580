import React, {useEffect, useState} from 'react'
import HeaderFooter from "../HeaderFooter"
import NavigationBar from '../NavigationBar.js'
import Footer from "../Footer"
import "./Impressum.css"
import CookieAlert from "../PUBLIC/CookieAlert"


function Impressum() {
	const [isDesktopNav, setIsDesktopNav] = useState(window.innerWidth > 1100 ? true : false) // 1290 normal

	// Event Listener
	useEffect(() => {
		window.addEventListener("resize", handleWindowSize) 
		return () => window.removeEventListener("resize", handleWindowSize)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDesktopNav])

	const handleWindowSize = () => {
		if (window.innerWidth > 1100) {
			if (!isDesktopNav) setIsDesktopNav(true)
		} else {
			if (isDesktopNav) setIsDesktopNav(false)
		}
	} 

	return(
		<div className="Impressum">
			{isDesktopNav ? <HeaderFooter data={{actAn: false}}/> : <NavigationBar states={{isInCollection: false} }/>}

			<div className="Impressum-wrapper">
				<div className="wrapper">
					<h1 className="header">IMPRESSUM</h1>

					<div className="infos-wrapper">
						<div className="first_container">
							<p className="title">ANGABEN gemäß § 5 TMG</p>
							<p className="content">HONK INTERNATIONAL UG (haftungsbeschränkt)</p>
							<p className="content">Auf dem Buck 11</p>
							<p className="content">79761 Waldshut-Tiengen</p>
							<p className="content-m">Handelsregister: HRB 720907</p>
							<p className="content">Registergericht: Amtsgericht Freiburg</p>
							<p className="content">Geschäftsführer: Sebastian Eppler</p>
						</div>

						<div className="seconde_container">
							<p className="title">KONTAKT</p>
							<p className="content">original@honk.international</p>
							<p className="content">+49 (0) 15252612398</p>
							<p className="title">UMSATZSTEUER-ID</p>
							<p className="content">Umsatzsteuer-Identifikationsnummer: DE327488108</p>
						</div>
					</div>

					<p className="content-recht">Alle Inhalte dieser Webseite sind urheberrechtlich geschützt.</p>

					<div className="block-container">
						<p className="title">Streitschlichtung</p>
						<p className="content">Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr</a>. Unsere E-Mail-Adresse finden Sie oben im Impressum.<br/> Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
					</div>

					<div className="block-container">
						<p className="title">HAFTUNG FÜR LINKS</p>
						<p className="content">Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.<br/> Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
					</div>
				</div>
			</div>

			{isDesktopNav ? "" : <Footer isSimilareItems={true}/>}
			<CookieAlert/>
		</div>
	)
}

export default Impressum