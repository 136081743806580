import React, {useState, useEffect, useRef} from 'react';
import './ImageCollection.css'
import { Link } from 'react-router-dom'


function ImageCollection(props) {

	const [styles, setStyles] = useState(props.states.styles ? props.states.styles : [])

	const refs = useRef([...Array(styles)].map(() => React.createRef()))
	const executeScroll = position => {window.scrollTo(0, position)}
	

	useEffect(() => {
		if (styles === null || styles.length <= 0) setStyles(props.states.styles)
		if (styles > 0 && props.states.shouldScroll) { scrollToItem() } 

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [styles, props.states.styles])	


	
	const scrollToItem = () => {
		try { 
			const scrollPosition = localStorage.getItem("scrollPosition")
			executeScroll(scrollPosition)
		} 
		catch(e) { console.log("get item or clear local storage failed: ", e) }
	}


	// Set Scroll Pos to LocalStorage
	const setLocalStorage = () => {
		try { 
			localStorage.setItem("scrollPosition", `${window.pageYOffset}`) 
			localStorage.setItem("saveToRemove", "false") 
		}
		catch(e) { console.log("set item to local storage failed: ", e) }
	}

	const setImages = () => {
		return styles.map(style => 
			<div className="imgContainer" key={style.styleID} ref={refs.current[style.styleID] = refs.current[style.styleID] || React.createRef()}>
				<Link onClick={setLocalStorage} to={{
					pathname: `/${props.states.gender}/${style.styleID}`,
					state: {
						toStyle: style
					}
				}}>
					<img className="image" 
						alt={`${props.states.gender} Outfit Idea ${style.styleID}`} 
						src={style.imgURLS_555} 
						srcSet={`${style.imgURLS_185} 185w,
								${style.imgURLS_370} 370w,
								${style.imgURLS_555} 555w,
								${style.imgURL_720} 720w`}
						sizes="(max-width: 1200px) calc(50vw - 5px), 21.53vw">	
					</img>
				</Link> 
			</div>
		)
	}

	return (
		<div className="collection">
			{styles !== null && styles.length > 0 ? setImages() : ""}
		</div>
	)
}

export default ImageCollection
