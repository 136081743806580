import React, {useState, useEffect} from 'react'
import "./StyleCollection.css"
import ImageCollection from './ImageCollection'
import NavigationBar from '../NavigationBar.js'
import Filter from './Filter'
import Footer from "../Footer"
import {sortNewest} from "../helper"
import CookieAlert from "../PUBLIC/CookieAlert"

function StyleCollection(props) {

  const [styles, setStyles] = useState([])
  const [counter, setCounter] = useState(0)
  const [shouldScrollToImage, setShouldScroll] = useState(false)
  const [isWomen, setIsWomen] = useState(false)

  // error handling
  const [isJsonLoading, setIsJsonLoading] = useState(false)
  const [isFetchErr, setIsFetchErr] = useState(false)

  useEffect(() => {
    document.title = `Infinit Tag | Streetwear outfit collection ${props}`
  }, [props])

  useEffect(() => {
    if (styles.length < 1) {
      let storageStyles
			try {
        storageStyles = JSON.parse(sessionStorage.getItem(`JsonData${props}`))
        setStyles(storageStyles)
      } catch(err) {
        console.log("sessionStorage error: ", err)
      }

      if (!storageStyles) fetchItems() 
      else setStyles(sortNewest(storageStyles))
    }
    

    
    return () => {
      try { 
        const isSaveToRemove = localStorage.getItem("saveToRemove")
        localStorage.removeItem(isSaveToRemove === "false" ? "" : "scrollPosition")
        localStorage.removeItem(isSaveToRemove === "false" ? "" : "sort")
        localStorage.removeItem(isSaveToRemove === "false" ? "saveToRemove" : "")
      } 
      catch(e) { console.log("local storage failed: ", e) }
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])	



  // FETCH JSON
	const fetchItems = async () => {

    if (props === "women") {
      setIsWomen(true)
      return
    }

    setIsJsonLoading(true)

    try { 
      console.log(props)
      const data = await fetch(`https://fashion-api-v1.herokuapp.com/api/${props}`) 
      // const data = await fetch(`http://localhost:3004/api/${props}`) 
      // const data = await fetch(`/testingFeed.json`) 
      const items = await data.json()
      setJsonData(items)

    } catch (err) {
      // const data = await fetch(`/Fashion_API/Fashion_${props}_Json.json`)
      console.log("Fetch JSON error: ", err)
      setIsFetchErr(true)
    }

    setIsJsonLoading(false)
  }
  

  const setJsonData = (items) => {
    items = sortNewest(items)
    setStyles(items)
    setIsFetchErr(false)
    try { sessionStorage.setItem(`JsonData${props}`, JSON.stringify(items)) }
    catch(e) {console.log(e)}
  }



  // Callback
  const setStylesCallback = (newStyleOrder, shouldScroll) => {
    setStyles(newStyleOrder)
    setCounter(counter + 1)
    setShouldScroll(shouldScroll)

    try {sessionStorage.setItem(`JsonData${props}`, JSON.stringify(newStyleOrder))}
    catch(e) {console.log(e)}
  }

  return (
      <div style={{backgroundColor: "white", position: "relative", minHeight: "100vh"}}> 
        <NavigationBar  states={{isInCollection: true, gender: props}}/>

        <div className="loadingAlert" style={{display: isJsonLoading || isFetchErr ? "" : "none"}}>
          <p> {isFetchErr ? "SOMETHING WENT WRONG, TRY AGAIN LATER." : isJsonLoading ? "LOADING..." : ""} </p>
        </div>

        <Filter states={{
          styles: styles, 
          setStylesCallback: setStylesCallback, 
          renderCounter: counter,
          gender: props
        }}/>

        {/* { isJsonLoading ? "" :
        <ImageCollection states={{
          gender: props, 
          styles: styles, 
          renderCounter: counter,
          shouldScroll: shouldScrollToImage
        }}/>
        } */}

        {/* <Route path={`/${props.match.params.gender}/preview_:id`} component={StylePresentation} /> */}

        {/* { isWomen ? */}
          <div className="comingsoon-div">
            <p>COMIG SOON</p>
          </div>
          {/* : ""
        } */}
        <div>
          <p></p>
        </div>

        <Footer isSimilareItems={true}/>

        <CookieAlert/>
      </div>
  )
}

export default StyleCollection