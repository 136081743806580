import React, {useState, useRef, useEffect} from 'react';
import './Filter.css'
import closeIcon from "../../components/ASSETS/Close_sort.svg"
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';


function Filter(props) {

	const filterArr = ["POPULAR", "NEWEST", "PRICE (LOW-HIGH)", "PRICE (HIGH-LOW)"]
	const [activFilter, setActivFilter] = useState("POPULAR")
	const [isFilterOpen, setIsFilterOpen] = useState(false)

	// mobile Sort Menu
	const [isMobile, setIsMobile] = useState(window.innerWidth > 750 ? false : true)
	const [isReadyToHide, setIsReadyToHide] = useState(true)

	const wholeFilterRef = useRef(null);

	// no Scroll target
	const targetRef = useRef(null)


	useEffect(() => {
		try { 
			const sortName = localStorage.getItem("sort")
			setActivFilter(sortName !== null ? sortName : "POPULAR")

			if (props.states.styles.length > 0 && sortName !== "POPULAR") {
				if (sortName === "PRICE (LOW-HIGH)") { sortPriceLH() }
				else if (sortName === null || sortName === "POPULAR" ) { sortPopular() }
				props.states.setStylesCallback(props.states.styles, true)
			}
		} 
		catch(e) { console.log("local storage failed: ", e) }


		window.addEventListener("resize", handleSize)
		return () => window.removeEventListener("resize", handleSize) 

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMobile])



	
	// Event Listener
	const handleSize = () => {
		if (window.innerWidth > 750) {
			if (isMobile) setIsMobile(false)
		} else {
			if (!isMobile) setIsMobile(true)
		}
	}


	// Sort functions
	// Price (Low-High)
	const sortPriceLH = () => {
		props.states.styles.sort((styleA, styleB) => {
			let sumA = 0
			for (let item of styleA.clothings) { sumA += parseFloat(item.price[0].replace(/,/, '.')) }
			let sumB = 0
			for (let item of styleB.clothings) { sumB += parseFloat(item.price[0].replace(/,/, '.')) }
			return sumA - sumB
		})
	}

	const sortPriceHL = () => {
		props.states.styles.sort((styleA, styleB) => {
			let sumA = 0
			for (let item of styleA.clothings) { sumA += parseFloat(item.price[0].replace(/,/, '.')) }
			let sumB = 0
			for (let item of styleB.clothings) { sumB += parseFloat(item.price[0].replace(/,/, '.')) }
			return sumB - sumA
		})
	}

	// Popular
	const sortPopular = () => {
		props.states.styles.sort((styleA, styleB) => {
			return styleA.favoriteIndex - styleB.favoriteIndex
		})
	}

	// Newest
	const sortNewest = () => {
		props.states.styles.sort((styleA, styleB) => {
			return styleB.styleID - styleA.styleID
		})
	}



	// Filter Clicked  
	const onFilterClick = (filterName) => {
		if (props.states.styles === null || props.states.styles === undefined) { return }

		setActivFilter(filterName)
		closeOpenFilter()
		
		try { 
			localStorage.setItem("sort", filterName) 
			localStorage.removeItem("scrollPosition")
		} 
		catch(e) { console.log("localStorage err: ", e) }

		if (filterName === "PRICE (LOW-HIGH)") {
			sortPriceLH()
			props.states.setStylesCallback(props.states.styles, false)
		} else if (filterName === "PRICE (HIGH-LOW)") {
			sortPriceHL()
			props.states.setStylesCallback(props.states.styles, false)
		} else if (filterName === "POPULAR") {
			sortPopular()
			props.states.setStylesCallback(props.states.styles, false)
		} else if (filterName === "NEWEST") {
			sortNewest()
			props.states.setStylesCallback(props.states.styles, false)
		}
	}


	// Close or Open Sort
	const closeOpenFilter = () => {
		if (isMobile) { 
			if (isFilterOpen) clearAllBodyScrollLocks()
			else disableBodyScroll(targetRef.current)

			if (isFilterOpen) setTimeout(() => {setIsReadyToHide(true)}, 400)
			else setIsReadyToHide(false)
		}

		setIsFilterOpen(!isFilterOpen)
	}

	const onOverlayClick = (e) => {
		if (e.target.className === "overlay-container") { closeOpenFilter() }
	}


	// Tablet / Desktop
	const wholeFilter = filterArr.map(filter => 
		filter !== activFilter ?  <button className="filterName" onClick={() => onFilterClick(filter)} key={filter}>{filter}</button> : ""
	)

	// Mobile
	const sortUI = filterArr.map(filter => 
		<button className="sortName" onClick={() => onFilterClick(filter)} style={activFilter === filter ? {color: "#282828"} : {}} key={filter}>{filter}</button>
	)


	// Tablet / Desktop
	const selectionStyles = {
		WebkitTransform: isFilterOpen ? "translateX(0px)" : `translateX(-${!wholeFilterRef.current || !wholeFilterRef.current.getBoundingClientRect().width ? "" : wholeFilterRef.current.getBoundingClientRect().width +  80}px)`,
		transform: isFilterOpen ? "translateX(0px)" : `translateX(-${!wholeFilterRef.current || !wholeFilterRef.current.getBoundingClientRect().width ? "" : wholeFilterRef.current.getBoundingClientRect().width + 80}px)`
	}


	//
	const overlayStyles = {
		visibility: isReadyToHide ? "hidden" : "visible",
		WebkitAnimation: isFilterOpen ? "openMenu 0.4s forwards" : "closeMenu 0.4s forwards",
		animation: isFilterOpen ? "openMenu 0.4s forwards" : "closeMenu 0.4s forwards"
	}

	const menuStyles = {
		maxHeight: isFilterOpen ? "214px" : "0px"
	}

	return(
		<div className="Filter">
			<button className="shortBy" onClick={closeOpenFilter}> 
				<span className="firstPart">SORT BY</span> 
				{isMobile ? "" : <span className="lastPart">{activFilter}</span>} 
			</button>

			{ isMobile ? 
				<div className="overlay-container" ref={targetRef} style={overlayStyles} onClick={(e) => onOverlayClick(e)}>
					<div className="menu-container" style={menuStyles}>
						<button className="closeButton" onClick={closeOpenFilter}>
							<img className="icon" src={closeIcon} alt="close"></img>
						</button>

						<div className="sortChoice">
							{sortUI}
						</div>
					</div>
				</div>
			:
				<div className="selection" ref={wholeFilterRef} style={selectionStyles}>
					{wholeFilter}
				</div>
			}
		</div>
	)
}


export default Filter
