import React, {useEffect, useState} from 'react'
import HeaderFooter from "../HeaderFooter"
import NavigationBar from '../NavigationBar.js'
import Footer from "../Footer"
import "./404.css"
import CookieAlert from "../PUBLIC/CookieAlert"
import {Link} from 'react-router-dom'


function Page404() {
	const [isDesktopNav, setIsDesktopNav] = useState(window.innerWidth > 1100 ? true : false) // 1290 normal

	// Event Listener
	useEffect(() => {
		window.addEventListener("resize", handleWindowSize) 
		return () => window.removeEventListener("resize", handleWindowSize)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDesktopNav])

	const handleWindowSize = () => {
		if (window.innerWidth > 1100) {
			if (!isDesktopNav) setIsDesktopNav(true)
		} else {
			if (isDesktopNav) setIsDesktopNav(false)
		}
	}

	return(
		<div className="WholePage404">
			{isDesktopNav ? <HeaderFooter data={{actAn: false}}/> : <NavigationBar states={{isInCollection: false} }/>}

			<div className="Page404">
				<div className="wrapper">
					<h1>404</h1>
					<h2>WE CAN’T FIND WHAT YOU’RE LOOKING FOR.</h2>
					<Link className="link" to={{pathname: "/"}}><p>BACK</p></Link>
				</div>
			</div>

			{isDesktopNav ? "" : <Footer isSimilareItems={true}/>}
			<CookieAlert/>
		</div>
	)
}

export default Page404