import React, {useState, useEffect, useRef} from "react"
import "./Share.css"
import CopyLinkIcon from "../../components/ASSETS/CopyLinkIcon.svg"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faTwitter, faFacebookF} from "@fortawesome/free-brands-svg-icons"
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';


// const scrollToTop = () => {
// 	window.scrollTo(0, 0) 
// }

function Share(props) {

	const [overlayStyle, setOverlayStyle] = useState({})
	const [cardStyle, setCardStyle] = useState({})
	const [firstLoad, setFirstLoad] = useState(true)	// Fix Bug where popup is flashing at first load
	const [linkAlertStyle, setLinkAlertStyle] = useState({})

	const [isCCLinkAlertOpen, setIsCCLinkAlertOpen] = useState(false)
	
	// no srcoll overlay
	const overlayRef = useRef(null)

	useEffect(() => {
		if (props.data.isShareOpen) disableBodyScroll(overlayRef.current)

		setOverlayStyle({
			display: props.data.isShareOpen ? "flex" : "none",
			animation: props.data.isShareOpen ? "shareColorchange 0.25s forwards, blurChange 0.25s forwards" : "",
			WebkitAnimation: props.data.isShareOpen ? "shareColorchange 0.25s forwards, blurChange 0.25s forwards" : ""
		})

		setCardStyle({
			animation: props.data.isShareOpen ? "opacityChange 0.25s forwards" : "",
			WebkitAnimation: props.data.isShareOpen ? "opacityChange 0.25s forwards" : ""
		})

		setFirstLoad(false)
	}, [props])


	// On Click Cancel Button
	const onCancelClick = () => {
		clearAllBodyScrollLocks()

		setOverlayStyle({
			animation: "shareColorchangeBack 0.25s forwards, blurChangeBack 0.25s forwards",
			WebkitAnimation: "shareColorchangeBack 0.25s forwards, blurChangeBack 0.25s forwards"
		})

		setCardStyle({
			animation: "opacityChangeBack 0.25s forwards",
			WebkitAnimation: "opacityChangeBack 0.25s forwards"
		})

		setTimeout(() => {
			props.data.shareCallback(false)
		}, 250)
	}


	// On Click Copy Button
	const onCopyClick = () => {
		// Copy to clipboard
		const textField = document.createElement('textarea');
		textField.innerText = `http://infinittag.de/${props.data.gender}/${props.data.styleId}`;
		document.body.appendChild(textField);
		textField.select();
		document.execCommand('copy');
		textField.remove();

		onCancelClick()

		if (!isCCLinkAlertOpen) {
			setLinkAlertStyle({transform: "translateY(-34px)"})
			setIsCCLinkAlertOpen(true)
			// scrollToTop()

			setTimeout(() => {
				setLinkAlertStyle({transform: "translateY(0px)"})
				setIsCCLinkAlertOpen(false)
			}, 3700)
		}
	}

	const onOverlayClick = (e) => {
		if (e.target.className === "Share-overlay") { onCancelClick() }
	}

	return (
		<div>
			<div className="linkCopiedAlert" style={linkAlertStyle}> <p>Link copied to clipboard</p> </div> 

			<div className="Share-overlay" ref={overlayRef} onClick={(e) => onOverlayClick(e)} style={firstLoad ? {display: "none"} : overlayStyle}>
				<div className="Share-container" style={cardStyle}>
					<div className="sozialButs">
						<button> <FontAwesomeIcon className="icon" icon={faFacebookF} /> </button>
						<button> <FontAwesomeIcon className="icon" icon={faTwitter} /> </button>
						<button onClick={onCopyClick}> <img className="icon" src={CopyLinkIcon} alt="copy link"></img> </button>
					</div>

					<div className="title-container">
						<p className="title">S<br/>H<br/>A<br/>R<br/>E</p>
					</div>

					<button className="cancelBut" onClick={onCancelClick}> <p>Cancel</p> </button>
				</div>
			</div>
		</div>
	)
}

export default Share