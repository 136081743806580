import React, {useEffect, useState} from "react"
import "./Home.css"
import HeaderFooter from "../HeaderFooter"
import CookieAlert from "../PUBLIC/CookieAlert"
import {Link} from 'react-router-dom'
import Footer from "../Footer"
import img from "./TestHome2x.jpg"
import imgWeb1 from "../../components/ASSETS/WEB_LandingPage_Pic1.jpg"
import imgWeb2 from "../../components/ASSETS/WEB_LandingPage_Pic3.jpg"

function Home() {
	const [isDesktopNav, setIsDesktopNav] = useState(window.innerWidth > 1290 ? true : false)

	// Event Listener
	useEffect(() => {
		window.addEventListener("resize", handleWindowSize) 
		return () => window.removeEventListener("resize", handleWindowSize)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDesktopNav])

	const handleWindowSize = () => {
		if (window.innerWidth > 1290) {
			if (!isDesktopNav) setIsDesktopNav(true)
		} else {
			if (isDesktopNav) setIsDesktopNav(false)
		}
	}


	// MOBILE NAV
	const mobileNav = () => {
		return (
			<div className="MobileHomeNav">
				<Link className="brand" to={"/"}>INFINIT TAG</Link>
				<h1>GET INSPIRED BY OUR OUTFIT COLLECTION</h1>
				<Link className="women" to={"/women"}>WOMEN</Link>
				<Link className="men" to={"/men"}>MEN</Link>
			</div>
		)
	}


	// IMAGES
	const images = () => {
		if (isDesktopNav) {
			// Desktop
			return (
				<div className="Desktop">
					<div className="header-2">
						<h2>GET INSPIRED BY OUR OUTFIT IDEAS</h2>
						<div className="line"></div>
					</div>
					<div className="Desktop-images">
						<img className="image1" src={imgWeb1} alt="Men Outfit"/>
						<img className="image2" src={imgWeb2} alt="Women Outfit"/>
					</div>
				</div>
			)
		} else {
			// Mobile
			console.log("elseelseelseelse")
			return (
				<div className="image">
					<img src={img} className="mainImage" alt="People with fresh outfits"/>
				</div>
			)
		}
	}

	return (
		<div className="Home">
			{isDesktopNav ? <HeaderFooter data={{actAn: false}}/> : mobileNav()}
			
			{images()}

			{isDesktopNav ? "" : <Footer isSimilareItems={true}/>}
			<CookieAlert/>
		</div>
	)
}

export default Home