import React from 'react'
import "./header.css"
import { Link } from 'react-router-dom'


function Header(props) {

	const openInApp = () => {
		const link = `https://toapp.infinittag.de/hello/?link=https%3A%2F%2Finfinittag.de%2Fprofil%2F${props.data.profil}&afl=https%3A%2F%2Fapps.apple.com%2Fca%2Fapp%2Fdeep-sale%2Fid1498228431&ibi=com.infinittag&ifl=https%3A%2F%2Fapps.apple.com%2Fca%2Fapp%2Fdeep-sale%2Fid1498228431&efr=1`
		window.open(link);
	} 

	const isIos = () => {
		return /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
	}

	return(
		<div className="Header-V2">
			<div className="header-V2-container">
				<Link className="title" to="/">INFINIT TAG</Link>
				<button className="open-in-app-button" onClick={openInApp} style={{display: isIos ? "inline-block" : "none"}}>OPEN IN APP</button>
			</div>
		</div>
	)
}

export default Header