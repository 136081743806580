export const addProfilJson = (json) => {
	return {
		type: "ADD_PROFIL_JSON",
		payload: json
	}
}

export const addUserOutfitsJson = (json) => {
	return {
		type: "ADD_USERS_OUTFITS_JSON",
		payload: json
	}
}