import React, {useEffect, useState} from "react"
import "./MainImage.css"
import circle from "../../components/ASSETS/Pointer_Circle_Web.svg"
import triangle from "../../components/ASSETS/Pointer_Triangle_Web.svg" 
import ShareIcon from "../../components/ASSETS/Share_Icon.svg" 
import {createImageUrl} from "../HELPER/helper"

function MainImage(props) {


	// Image
	const [mainImage, setMainImage] = useState([])
	const [pointersInfos, setPointersInfos] = useState([]);

	// Pointer
	const [shouldHide, setShouldHide] = useState(true)
	const [currentTimeout, setCurrentTimeout] = useState()

	// 
	const [deviceSize, setDeviceSize] = useState(window.innerWidth > 1290 ? 0 :  window.innerWidth > 750 ? 1 : 2) 		// 0 = Desktop, 1 = Tablet, 2 = Phone

	const navBarHeight = 72
	const navBarHeightPhone = 48


	useEffect(() => {

		// Check window resize
		function handleResize() {
			checkImageClipping(mainImage, window.innerWidth > 1290 ? 0 : window.innerWidth > 750 ? 1 : 2)

			if (window.innerWidth > 1290) {
				if (deviceSize !== 0) setDeviceSize(0)

			} else if (window.innerWidth > 750) {
				if (deviceSize !== 1) setDeviceSize(1)

			} else {
				if (deviceSize !== 2) setDeviceSize(2)
			}
		}
	
		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainImage, deviceSize])


	useEffect(() => {
		checkImageClipping(mainImage, window.innerWidth > 1290 ? true : false)

		console.log("test ", props.data.clothings)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.data.clothings])




	// Image Load
	const onImgLoad = ({target: img}) => {
		setMainImage(img)
		
		if (props.data.clothings.length > 0) checkImageClipping(img, window.innerWidth > 1290 ? true : false)
	}

	// Image Click
	const onImageClick = () => {
		setShouldHide(!shouldHide)
	}

	// Calculate Pointers Layout
	const checkImageClipping = (img) => {
		const clothings = props.data.clothings
		const imgSizeLock = 1.25
		const pointerHeight = 15

		let array = []

		if (img.width * imgSizeLock > img.height) {
			//  image clipped at sides
			const wholeImgHeight = img.width * imgSizeLock
			const topCroppedPx = (wholeImgHeight - img.height) / 2
			const wholeImgWidth = img.width

			for (let i = 0; i < clothings.length; i++) {
				array.push({
					marginTop: (wholeImgHeight * (clothings[i].poiterTop)) - topCroppedPx - pointerHeight,
					marginLeft: wholeImgWidth * (clothings[i].poiterLeft),	
					brand: clothings[i].brand,
					id: i
				})
			}

		} else {
			//  image clipped at top and bottom
			const wholeImgWidth = (img.height) / imgSizeLock
			const leftCroppedPx = (wholeImgWidth - img.width) / 2
			const wholeImgHeight = img.height

			for (let i = 0; i < clothings.length; i++) {
				array.push({
					marginTop: wholeImgHeight * (clothings[i].poiterTop) - pointerHeight,
					marginLeft: wholeImgWidth * (clothings[i].poiterLeft) - leftCroppedPx,
					brand: clothings[i].brand,
					id: i
				})
			}
		}

    setPointersInfos(array)
  } 


	// *--- POINTER --*
	// Pointer Click
	const onPointerClick = id => {
		if (shouldHide) {
			setShouldHide(false)
			return
		}

		props.data.refs[id].current.scrollIntoView({
			behavior: 'smooth',
			block: 'end'
		})


		if (deviceSize === 2) return

		if (!props.data.isHilighted[id]) { 
			clearTimeout(currentTimeout)

			props.data.setIsHilighted(props.data.clothings.map(clothing => id === clothing._id ? true : false))

			setCurrentTimeout(
				setTimeout(() => {
					props.data.setIsHilighted(props.data.clothings.map(() => false))
				}, 1000)
			)
		}
	}
 
	const pointerStyles = (pointer) => {
		return ({
			display: pointer.marginTop >= (window.innerHeight - 30) && deviceSize === 3 ? "none" : "",
			marginLeft: `${pointer.marginLeft}px`, 
			marginTop: `${pointer.marginTop}px`,
			transform: shouldHide ? "translateX(-20px)" : "translateX(0px)",
			opacity: shouldHide ? "0" : "1"
		})
	}

	const pointer = pointersInfos.map(pointer => 
		// <div className="pointer-conatiner" style={pointerStyles(pointer)} key={`${pointer.brand} ${pointer.title}`}>
		
		<div className="pointer-conatiner" style={pointerStyles(pointer)} key={`${pointer.brand} ${pointer.marginTop}`}>
			<button onClick={() => onPointerClick(pointer.id)}>
				<div className="pointer-button-container">
				<img className="pointer-triangle" src={triangle} alt={`triangle: ${pointer.id}`}></img>
				<img className="pointer-circle" src={circle} alt={`circle: ${pointer.id}`}></img>
				<div className="pointer-title-container"> <p>{pointer.brand}</p> </div>
				</div>
			</button>
		</div>
	)


	// Image Height Style
	const imageHeight = {
		paddingTop: deviceSize === 0 ? 0 : deviceSize === 1 ? navBarHeight : navBarHeightPhone
	}

	// Image Height Style
	const navMargin = {
		marginTop: deviceSize === 0 ? 0 : deviceSize === 1 ? navBarHeight : navBarHeightPhone
	}

	return(
		// <div className="mainImage-container" style={{width: `${window.innerHeight * 1.50146628}`}}>
		<div className="mainImage-container" style={imageHeight}>
			{/* Pointer */}
			{pointer}

			{/* Share */}
			{deviceSize === 2 ? "" : 
				<button className="share" style={imageHeight} onClick={() => props.data.onShareCallack(true)}> <img src={ShareIcon} alt="share"></img> </button>
			}

			{/* Press Animation */}
			<div className="press-icon" style={navMargin}></div>

			{/* Image */}
			<button className="imageButton" onClick={onImageClick}>
				<img className="mainImage" 
					alt={`Outfit`} 
					onLoad={onImgLoad} 
					src={createImageUrl(props.data.style.imageUrl, window.innerWidth >= 500 ? 1640 : window.innerWidth * window.devicePixelRatio, undefined, 80)}
					></img>
			</button>
	  </div>
	)
}

export default MainImage