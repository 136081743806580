import React from 'react'
import "./terms.css"
import NavigationBar from '../NavigationBar.js'
import Footer from "../Footer"


function Terms() {

	return(
		<div className="TermsOfUse">

			<NavigationBar states={{isInCollection: false}} />

			<div className="terms-content">
				<h2>GENERAL TERMS OF USE AND LEGAL INFORMATION</h2>
				<p>The following general terms of use and legal information (hereinafter, "Terms of Use") govern the use and access to the website infinittag.com and the corresponding mobile application (hereinafter referred to collectively as the "Platform"), title INFINIT TAG(Hereinafter, "Infinit Tag").</p>
				<p>In compliance with the provisions of Article 10 of Law 34/2002, of the 11th of July, on the Information Society and Electronic Commerce Services, the following data pertaining to the owner of the Platform is outlined:</p>

				<ul className="info">
					<li>Company name: HONK International UG (haftungsbeschränkt)</li>
					<li>Office: Werner-Sombart-Str. 37, 78464 Konstanz, Germany</li>
					<li>Handelsregister: HRB 720907, Registergericht: Amtsgericht Freiburg</li>
					<li>Umsatzsteuer-Identifikationsnummer: DE327488108</li>
					<li>Email: original@honk.international</li>
				</ul>

				<p>Registration, access and browsing on the Platform as well as downloading and installing the App Infinit Tag implies a full and unreserved acceptance of these present Terms of Use on the behalf of browsers and platform users.</p>
			

				<div className="liste-container">
					<ol>
						<li>OBJECT
							<ul>
								<li>Infinit Tag is a fashion social network that aims to provide community members (hereinafter, the "Users") a platform and method for sharing photos containing clothes or accessories and opinions about them, with the intention of sharing decisions to purchase with other users through a simple and intuitive access to online stores and fashion companies.</li>
								<li>The present Terms of Use are intended to regulate the terms and conditions that apply to the provision and management of information provided through the platform and the services offered by HONK International. These conditions incorporate our privacy policy, accessible on the application found in the Preferences section of the Platform.</li>
							</ul>
						</li>
						<li>SIGN-UP, REGISTRATION AND USER PROFILES
							<ul>
								<li>To be a user of the platform it is essential that the following requirements are met:</li>
								<li>
									<ul>
										<li>Truthfully complete the required fields on the registration form, in which personal data such as name and email address.</li>
										<li>Accept the present Terms of Use (including our Privacy Policy), expressed through proceeding with the registration on the Platform.</li>
									</ul>
								</li>
								<li>Provision of services by Infinit Tag is free for all Users. However, some of the services provided by the provider through the application are subject to the payment of a specific price in these general terms and conditions of business.</li>
								<li>In the case where it is estimated that a user may be under 18 years old, Infinit Tag reserves the right to require that the user provides evidence to society that he/she does have the age, if not and is less, the expressed permission of their parents or legal guardians to accept these Terms of Use and perform all actions that are available to them on the Platform.</li>
								<li>The User guarantees that all data concerning the identity and legitimacy provided to Infinit Tag on their registration forms of the Platform are truthful, accurate and complete. Also, commits to keeping the data updated at all times. In the event that the User provides any false, inaccurate or incomplete data or if Infinit Tag considers that there are grounds to doubt the truthfulness, accuracy and completeness of these, Infinit Tag may deny access and present or future use of the platform or any of its contents and/ or services; likewise terminating the User and cancelling the account.</li>
								<li>If the user subscribes through an account other social network / portal or internet service, he/she accepts the validity and effectiveness of all communications sent by Infinit Tag to that account.</li>
								<li>When registering on the Platform the user selects a user name and a password in the case of not registering.</li>
								<li>Both the username and password, as the data of the social media account you use to access the platform (usually username, password and e-mail used to access said account) are strictly confidential, personal and non-transferable (collectively the "Access Keys"). The User agrees not to disclose these access keys or make them accessible to third parties. Since Infinit Tag cannot guarantee the identity of registered users, the User shall be solely liable for use of Access Keys by third parties, including the findings made on the Platform or through the Platform, or any other action that is carried out by using the access keys.</li>
								<li>Users are obliged to promptly disclose to Infinit Tag the theft, disclosure or loss of the access keys, corresponding with Infinit Tag via the email support@infinittag.com</li>
								<li>Once registration is completed, any user can access his/her profile and complete and/ or edit as it deems appropriate, provided it complies with these present Terms of Use.</li>
							</ul>
						</li>
						<li>UNSUBSCRIBING OF USERS AND TERMINATION OF SERVICES
							<ul>
								<li>In order to unsubscribe, you can go directly to the application at any time, enter into the section “profile, options, edit profile” and choose the option to unsubscribe.</li>
								<li>In case the previous option is not enabled or does not work for any reason, you can also revoke your consent at any time by sending an email with the subject "User Drop" to support@infinittag.com, including in the content of the email the username (username) and the email or username of the social network that you used when registering with the Platform.</li>
								<li>Infinit Tag may suspend or terminate a User (and therefore choose to suspend or terminate this contract) (i) immediately if the user fails to comply with any requirement of the Terms of Use or (ii) immediately if Infinit Tag stops providing the services offered through the Platform or (iii) if the user is idle for more than 6 months (in the latter case, you will be notified in writing 15 days before cancellation) (iv) any time and without stating any cause (by written notice sent 30 days prior to cancellation or suspension).</li>
								<li>The fact that a user is unsubscribed or the user him/ herself unsubscribes involves the termination of the Terms of Use (although certain clauses may continue to have legal implications depending on the nature of such).</li>
								<li>In the event of contract termination, the User agrees to (a) remove any copies they have of the implementation of the Platform and (b) to discontinue their use of the Platform. In addition, access to the User Account and all its contents will be deactivated and its contents removed, unless there are backups kept (which are used exclusively for data recovery or "back up" and legal defence within the interests of Infinit Tag).</li>
							</ul>
						</li>
						<li>USER OBLIGATIONS
							<ul>
								<li>Users are solely responsible for the access to and proper use of their profile and other contents on the Platform, subject to the law, whether national or international, as well as the principles of good faith, morals, good customs and public order. Specifically but without limitation, they agree to diligently observe these Terms of Use.</li>
								<li>Generally, users will refrain from using their profile and the remaining content and services of the Platform, for purposes or effects (a) other than to share photos, opinions and purchasing decisions on garments, clothing and fashion or (b) are unlawful or harmful to the rights and interests of others, or in any way damage, disable, affect or impair the Platform, its contents and services. It is also prohibited to use the platform in any way that prevents or hinders the proper functioning of the Platform or the normal use or enjoyment of the Platform to other users.</li>
								<li>Users hereby agree to (including but not limited to): <br/> Provide accurate and updated information, in any communication with Infinit Tag or any content or information communicated on the Platform.
											Not use personal data – whether the users own or third party- containing sensitive information relating to identified or identifiable individuals, such as: racial origin; union membership; religion, ideology or sexual orientation; health data; or data relating to criminal records, procedures and sanctions or fines arising thereof or breaches of monetary obligations.
											Not use false identities or impersonate others in the use of the Platform or using any services thereof.
											Not use the Platform for illegal or unauthorized purposes.
											Not host, store, disclose, publish, distribute or share any content that may involve unlawful interference in or violation of any form of fundamental rights to the honour, image and personal and family privacy of third parties and, especially of underage persons.
											Not publish on the platform any comments or information that is illegal, racist, xenophobic, obscene, pornographic, abusive, defamatory, misleading, fraudulent or otherwise contrary to morality or public order.
											Do not alter or modify the Platform, in whole or in part, circumventing, disabling or manipulating any other functions or services of the same.
											Abide by our Privacy Policy and not infringe the rules governing the protection of personal data.
											Not enter, store or transmit through the platform any content that infringes industrial property rights and/ or intellectual or confidential information from third parties, or in general any content that does not hold, in accordance with the law, the right to make it available to third parties.
											Not use the platform to injure, defame, intimidate, violate the self-image of or harass other users.
											Not access the email accounts of other users.
											Not enter, store or transmit via Platform any programs, data, code, computer viruses, corrupted files, or any other electronic or fixed devices that may cause damage or alterations in any of the computers, networks, contents or systems of Infinit Tag, or any other users or third parties.
											Not destroy, alter, use to use, disable or damage data, information, programs or electronic documents of Infinit Tag, its users or third parties.
											Not send emails to other users for commercial purposes, or provide or send email addresses to third parties without their consent.
											Not make fraudulent use of the reward system in order to obtain revenue from fake purchases, either through fake users or any forms of suspicious behaviour.
											Comply at all times with the advertising regulation, administrative regulations and advertising self-regulation along with not making "influential" comments contrary to lawful competition." </li>
								<li>Those who violate such obligations shall be liable for any loss or damage incurred. To the maximum extent permitted by the relevant law, Infinit Tag will not be responsible for any consequences or damages that may arise from such access or illicit use by third parties.</li>
								<li>Access to the platform may be refused by Infinit Tag to those users who, in any way, violate the obligations or violate these present Terms of Use or any other legal text included in the Platform, such as the Privacy Policy.</li>
							</ul>
						</li>
						<li>CONTENT GENERATED BY THE USERS
							<ul>
								<li>Users may post photographs, opinions and comments on the Platform ("User Content").</li>
								<li>In accordance with the provisions above, the Users guarantee that User Content does not infringe any intellectual property rights, the moral rights of any person or entity, or any trademark rights, privacy or image; and that they have obtained all necessary authorizations from the models, as well as data releases for the purposes of advertising, commercial or business of a similar nature, in compliance.</li>
								<li>Users expressly acknowledge and agree that Infinit Tag does not monitor, edit or control the User Content. Notwithstanding the foregoing, Infinit Tag reserves the right to cancel, withdraw or not to publish, at any time and without notice to users, any User Content it deems, in its sole discretion, as unsuitable or that violates the present Terms of Use, the law or any rights of third parties.</li>
							</ul>
						</li>
						<li>BREACHES / COMPLAINTS
							<ul>
								<li>Any User may inform Infinit Tag when it considers that another user is violating these Terms of Use via email support@infinittag.com or by clicking the "Report" that is marked with the symbol "..." and marking or specifying the reasons why said photography or content specifically violates these present Terms of Use.</li>
								<li>Infinit Tag shall verify this report as soon as possible, and take the measures it deems appropriate, reserving the right to withdraw and/ or suspend any User of the Platform for breach of these Terms of Use. Infinit Tag also reserves the right to withdraw and/ or suspend any User Content deemed illegal or offensive, without prior notice or subsequent notification.</li>
							</ul>
						</li>
						<li>RESPONSIBILITY OF INFINIT TAG
							<ol className="inner-num-list">
								<li>FUNCTIONING
									<ul>
										<li>The User is responsible for furnishing the services and equipment required to connect to the Internet and to access the Platform. In the event of any incident or difficulty accessing the Platform, the User can report to Infinit Tag via email at support@infinittag.com, which shall analyse the incident and give instructions to the user on how to resolve it as soon as possible.</li>
										<li>Infinit Tag will not respond in cases of service interruptions, connection errors, unavailability or deficiencies in accessing Internet services or interruptions of the Internet or for any other reason beyond its control.</li>
										<li>Infinit Tag reserves the right to terminate your access to the platform at any time and without notice, either for technical reasons, security, control, maintenance, by power failure or any other cause.</li>
									</ul>
								</li>
								<li>USAGE BY THE USER AND USER CONTENT
									<ul>
										<li>Infinit Tag has no obligation to and does not control the use that Users of the platform make and therefore does not guarantee that users use the platform in accordance with the provisions of these present Terms of Use, or make diligent use and/ or prudent thereof. Infinit Tag has no obligation to check and verify the identity of users or the accuracy, validity, completeness and/ or authenticity of the data they provide.</li>
										<li>Consequently, Infinit Tag is not responsible for the use that users make of the content of the Platform that may involve a violation of any rule, national or international, rights of intellectual property or any other third party rights. In particular, Infinit Tag publishing cannot be held responsible, and expressly states that it is not identified with any of the User Content, the consequences of which are the sole responsibility of the issuer thereof.</li>
										<li>To the maximum extent permitted by applicable law, Infinit Tag excludes any liability for damages of any kind that may result from the illicit use of the Platform by Users or that may result from the lack of veracity, completeness and / or authenticity of the information that users provide to other users about themselves, including but not limited to damages of any kind that may be due to the impersonation of a third party by a User in any kind of correspondence via the Platform.</li>
										<li>Users assume all liability for the use of the Platform, being solely responsible for any direct or indirect effect on the Platform derived, including but not limited to, all economic, technical and/ or legal aspects, and the failure to meet expectations generated by this platform, compelling the users to release Infinit Tag of all liability for any claims arising directly or indirectly from such facts.</li>
										<li>Notwithstanding the foregoing, Infinit Tag reserves the right to limit, in whole or in part, access to the Platform to certain users, and to cancel, suspend, block or remove certain types of content, using suitable technological tools the effect, if it had actual knowledge that the activity or information stored is unlawful or harms property or rights of a third party or is fraudulent. In this regard, Infinit Tag may implement the necessary filters to prevent that via our service, illegal or harmful content be posted on the Internet.</li>
									</ul>
								</li>
							</ol>
						</li>
						<li>PROTECTION OF PERSONAL DATA
							<ul>
								<li>The provider is deeply committed to compliance with personal data protection regulations and hereby guarantees full compliance with the obligations set forth, as well as the implementation of the security measures provided for in the European Data Protection Regulation and the German regulations for the protection of personal data. For more information, please see our Privacy Policy.</li>
							</ul>
						</li>
						<li>INTELLECTUAL PROPERTY
							<ul>
								<li>Infinit Tag is the owner or licensee of all intellectual and industrial property including all of the content accessible on it and through the Platform, including but not limited to texts, images, photographs, graphic design, navigation structure, information, source code, databases and any other contents included in the Platform.</li>
								<li>User authorization for access, use of the platform and the services offered therein do not imply any transfer of intellectual or industrial property on it or such content except as mentioned below.</li>
								<li>Infinit Tag grants the User a limited license to access and make personal use of the platform and the services offered therein, also to download the mobile application. Such license only grants the essential intellectual property rights for the purpose of complying with these Terms of Use (right of use and unique reproduction of the mobile application in a single backup device) it is non-exclusive, global and its duration is limited only to the validity of the contractual relationship with Infinit Tag users under these Terms of Use.</li>
								<li>Any user who proceeds to share User content through the platform guarantees (a) free licensing to Infinit Tag and the users of the Platform, pertaining only to the intellectual property rights necessary for the purposes of the platform and also for advertising purposes, promotional, educational and/ or identification of the platform (rights of public communication, reproduction in the platform and transformation for the sole purposes of adaptation/ editing content on the platform) on a non-exclusive basis, globally and for the maximum legal duration of the rights granted (b) they hold the rights necessary to do so and (c) will release Infinit Tag of any responsibility for the content and legality of the information published and shall compensate them in the event of the breach of this warranty.</li>
							</ul>
						</li>
						<li>LINKS
							<ul>
								<li>On the Platform the User may find links to other websites operated by third parties. Establishing any links, redirections or associations ("links") to other websites (www) enabled from the Platform does not imply any kind of relationship, collaboration or dependency between Infinit Tag and those responsible for the other websites and does not imply a guarantee to the user of Infinit Tag of the content of said website.</li>
							</ul>
						</li>
						<li>SEVERABILITY
							<ul>
								<li>If any of the clauses from these present Terms of Use should be nulled of full right or voidable, said condition shall be removed. Such a declaration of invalidity shall not invalidate the rest of the Terms of Use, which shall remain valid and effective.</li>
							</ul>
						</li>
						<li>PLATFORM UPGRADE AND MODIFICATION
							<ul>
								<li>Infinit Tag reserves the right to modify at any time without prior notice, the structure, configuration and design of the platform as well as these present Terms of Use and Privacy Policy. Users should carefully read these Terms of Use to access the platform. In any case, browsing of and simple access to the Platform involves the acceptance of any amendment. An updated version of these Terms of Use will be available permanently on the Platform.</li>
								<li>Also, Infinit Tag reserves the right to make, at any time and without notice, upgrades, modifications or deletion of information contained in its platform in the configuration and presentation thereof along with the conditions of access, without assuming any responsibility for it. Infinit Tag does not guarantee an interruption or error-free service when accessing the Platform or its content, nor that such information is always up to date, however, Infinit Tag will perform, provided that no causes that make these impossible or difficult to execute and as soon as it becomes aware of the errors, disconnections or lack of updated content, all work required to correct such errors, restore communication and update contents.</li>
							</ul>
						</li>
						<li>UPDATING THE TERMS OF USE
							<ul>
								<li>Infinit Tag reserves the right to modify this policy in order to adapt it to any legislative or case-law changes.</li>
								<li>In any case, any modification of the privacy policy shall be duly notified to users.</li>
							</ul>
						</li>
					</ol>
				</div>
			</div>

			<Footer isSimilareItems={true}/>
		</div>
	)
}

export default Terms