import React, {useEffect, useState} from 'react'
import Footer from "../Footer"
import "./Datenschutz.css"
import CookieAlert from "../PUBLIC/CookieAlert"
import {Link} from 'react-router-dom'


function AppDatenschutz(props) {

	useEffect(() => {
		manage()
	}, [])

	const manage = () => {
		const w = window
		const d = document
		// var loader = function () {
		// 	var s = d.createElement("script"), 
		// 	tag = d.getElementsByTagName("script")[0]; 
		// 	s.src="https://cdn.iubenda.com/iubenda.js"; 
		// 	tag.parentNode.insertBefore(s,tag);
		// }; 
		
		// if(w.addEventListener)
		// 	{w.addEventListener("load", loader, false)
		// } else if(w.attachEvent){
		// 	w.attachEvent("onload", loader);
		// }else{
		// 	w.onload = loader;
		// }


		var loader = function () {
			var s = d.createElement("script"), 
			tag = d.getElementsByTagName("script")[0]; 
			s.src="https://cdn.iubenda.com/iubenda.js"; 
			tag.parentNode.insertBefore(s,tag);
		}; 
			
		if(w.addEventListener){ 
			w.addEventListener("load", loader, false);
		}else if(w.attachEvent){
			w.attachEvent("onload", loader);
		} else {
			w.onload = loader;
		}
	}

	return( 
		<div className="AppDatenschutz">
			<div className="brand">
				<div className="wrapper">
					<Link className="h1" to="/"><p>INFINIT TAG</p></Link>
					<p className="h2">DATENSCHUTZ</p>
				</div>
			</div>

			<div className="extern-link">
				<a href="https://www.iubenda.com/privacy-policy/73716737" class="iubenda-white no-brand iubenda-embed iub-legal-only iub-body-embed" title="Privacy Policy">Privacy Policy</a>
			</div>

			<Footer />
		</div>
	)
}

export default AppDatenschutz