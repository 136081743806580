import React, {useEffect, useState} from 'react'
import HeaderFooter from "../HeaderFooter"
import NavigationBar from '../NavigationBar.js'
import Footer from "../Footer"
import "./Contact.css"
import CookieAlert from "../PUBLIC/CookieAlert"

function Contact(props) {

	const [isDesktopNav, setIsDesktopNav] = useState(window.innerWidth > 1100 ? true : false) // 1290 normal

	// Event Listener
	useEffect(() => {
		window.addEventListener("resize", handleWindowSize) 
		return () => window.removeEventListener("resize", handleWindowSize)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDesktopNav])

	const handleWindowSize = () => {
		if (window.innerWidth > 1100) {
			if (!isDesktopNav) setIsDesktopNav(true)
		} else {
			if (isDesktopNav) setIsDesktopNav(false)
		}
	} 


	return (
		<div>
			{isDesktopNav ? <HeaderFooter data={{actAn: false}}/> : <NavigationBar states={{isInCollection: false} }/>}

			<div>
				<a className="mail" href="mailto:original@honk.international">ORIGINAL@HONK.INTERNATIONAL</a>
			</div>
			<CookieAlert/>
			{isDesktopNav ? "" : <Footer isSimilareItems={true}/>}
		</div>
	)
}

export default Contact