import React, {useState, useEffect} from "react"
import "./CardsV2.css"
import { Link } from 'react-router-dom'
import { getCookie } from '../helper'
import firebase from "firebase/app"
import {createImageUrl} from "../HELPER/helper"

function Cards(props) {

	// Images
	const [imgHeightArr3, setImgHeightArr3] = useState([0])
	const [imgHeightArr2, setImgHeightArr2] = useState([0])
	const [imgHeightArr1, setImgHeightArr1] = useState([0])
	const [undercover, setUndercover] = useState(0)

	// Wondow Size 
	const [windowSize, setWindowSize] = useState(window.innerWidth < 935 ?  window.innerWidth < 745 ? 1 : 2  : 3) 
		
	const [isMobile, setIsMobile] = useState(false)

	// const [isStockOpenArr, setIsStockOpenArr] = useState([])
	// const [stocklistIndex, setStocklistIndex] = useState([])


	useEffect(() => {
		window.addEventListener("resize", resize)
		return () => { window.removeEventListener("resize", resize) }

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [windowSize])


	const resize = () => {
		if (window.innerWidth <= 934) {
			if (!isMobile) setIsMobile(true)
		} else {
			if (isMobile) setIsMobile(false)
		}

		if (window.innerWidth < 745) {
			if (windowSize !== 1) setWindowSize(1)
		} else if (window.innerWidth < 935) {
			if (windowSize !== 2) setWindowSize(2)
		} else {
			if (windowSize !== 3) setWindowSize(3)
		}
	}


	// Image load
	const onItemImageLoad = (id, {target: img}) => {
		const widthLock = img.naturalHeight / img.naturalWidth
		imgHeightArr3[id] = 216 * widthLock
		imgHeightArr2[id] = 258 * widthLock
		imgHeightArr1[id] = 210 * widthLock
		setImgHeightArr3(imgHeightArr3)
		setImgHeightArr2(imgHeightArr2)
		setImgHeightArr1(imgHeightArr1)
		setUndercover(undercover + 1)
	}



	// On Click
	const onStocklistClick = (index, target) => {
		let arr = []
		for (let i in props.data.clothings) { arr.push(index === Number.parseInt(i) ? target : props.data.isStockOpenArr[i]) }
		props.data.setIsStockOpenArr(arr)
	}

	const onBuyClick = (link, itemName) => {
		const userAcceptStats = getCookie("userAcceptStats")
		if (userAcceptStats) {
			if (userAcceptStats === "true") { 
				const analytics = firebase.analytics();
				analytics.logEvent("getTo_item", {product: itemName})		
			}
		} 
		window.open(link, '_blank')
	}



	// Card Style
	const cardStyles = (post) => {
		return {
			gridTemplateColumns: windowSize === 1 ? "288px" : windowSize === 2 ? "378px auto" : "256px" ,
			gridTemplateRows: 	
				windowSize === 1 ? `minmax(233px, auto) minmax(20px, auto) 110px` : 
				windowSize === 2 ? "minmax(167px, auto) 73px" :
				`minmax(246px, auto) minmax(20px, auto) 113px`,
	
			WebkitTransform: props.data.isHilighted[post.id - 1] ? "scale(1.05)" : "scale(1)",
			transform: props.data.isHilighted[post.id - 1] ? "scale(1.05)" : "scale(1)"
		}
	}



	// Cards
	const renderCards = () => {
		let cards = []
		const clothings = props.data.clothings

		for (let i = 0; i < clothings.length; i++) {
			cards.push(
				<div className="Card" 
				style={cardStyles(clothings[i])}
				ref={props.data.refs[i]} 
				key={i}
				>

					{/* Background */}
					<div className="background"></div>

					{/* IMAGE */}
					<div className="image-container" style={{height: `${windowSize === 1 ? imgHeightArr1[i] : windowSize === 2 ? imgHeightArr2[i] : imgHeightArr3[i]}px`}}>
						<img 
							src={clothings[i].imageUrls[0] === "" || clothings[i].imageUrls[0] === undefined ? createImageUrl(props.data.outfit.imageUrl, 400, undefined, 80) : createImageUrl(clothings[i].imageUrls[0], 400, undefined, 80)} 
							style={{
								width: `${windowSize === 1 ? 216 : windowSize === 2 ? 258 : 210}px`,
								height: `${windowSize === 1 ? imgHeightArr1[i] : windowSize === 2 ? imgHeightArr2[i] : imgHeightArr3[i]}px`
							}} 
							onLoad={(target) => onItemImageLoad(i, target)}
							alt={`Outfit item ${clothings[i].title}`}>
						</img>
					</div>


					{/* INFO TITLE */}
					<div className="info-container">
						<p className="brand">{clothings[i].brand}</p>
						<p className="title">{clothings[i].title}</p>
						<p className="price"> {clothings[i].price[0]} EUR </p>
						<div className="more-infos-container">
							<div className="more-info">
								<p className="title">SHOP</p>
								<p className="text">{clothings[i].shops[0]}</p>
							</div>
							<div className="more-info">
								<p className="title">SIZE</p>
								<p className="text">{clothings[i].size}</p>
							</div>
						</div>
					</div>


					{/* ALTERNATIV & BUY */}
					<div className="button-container">
						<Link className="alternativ-link">
							<div className="alternativ-button">ALTERNATIV</div>
						</Link>
						<button className="buyBut" onTouchStart={() => {}} onClick={() => onBuyClick(clothings[i].affiliateLinks[0], clothings[i].shop)}> 
							BUY
						</button>
					</div>
				</div> 
			)
		}

		return cards
	}	
	


	return (
        <div className="cardCollection-container">
			<h1 className="title1">OUTFIT ITEMS</h1>
			{renderCards()}
			{/* <Footer /> */}
		</div>
	)
}

export default Cards