import React , {useEffect, useState} from 'react'
import "./ResetPassword.css"
import Footer from "../Footer"
import CookieAlert from "../PUBLIC/CookieAlert"
import Header from "../V2/HEADER/header"
import Check from "../ASSETS/Check.svg"


function ResetPassword(props) {

	//
	const [password, setPassword] = useState("")
	const [repeatPassword, setRepeatPassword] = useState("")

	//
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState("")
	const [isChanged, setIsChanged] = useState(false)


	useEffect(() => {
		document.title = `Reset Password - Infinit Tag`
	}, [])

 
	// Submit
	const handleSubmit = (evt) => {
		evt.preventDefault()

		if (password !== repeatPassword) {
			setError("The password and the confirmation password must match.")
			return
		}

		setError("")
		setIsLoading(true)

		// fetch(`http://localhost:3000/api/user/password/reset/${props.match.params.userId}/${props.match.params.token}`, {
		fetch(`https://infinit-tag-api.herokuapp.com/api/user/password/reset/${props.match.params.userId}/${props.match.params.token}`, {
			method: 'post',
			headers: {'Content-Type':'application/json'},
			body: JSON.stringify({
					"password": password
			})
		})
		.then((response) => response.json())
		.then((responseJson) => {
			setIsLoading(false)
			if (responseJson.message === "ok") {
				setIsChanged(true)
			} else {
				setError(responseJson.message)
				console.log("error: ", responseJson.message)
				console.log(responseJson)
			}
		})
		.catch((error) => {
			setIsLoading(false)
			setError("Something went wrong")
			console.error(error)
		})
	}


	return(
		<div className="Reset-Password">
			<Header/>

			<div className="content-container">
				<h2>RESET PASSWORD</h2>
				<form className="form" onSubmit={handleSubmit} style={{display: isChanged ? "none" : "flex"}}>
					<input
						className="password-text-field"
						type="password"
						value={password}
						placeholder="PASSWORD"
						onChange={e => setPassword(e.target.value)}
					/>
					<input
						className="repeat-password-text-field"
						type="password"
						value={repeatPassword}
						placeholder="CONFIRM PASSWORD"
						onChange={e => setRepeatPassword(e.target.value)}
					/>

					<label className="error-label">{error === null ? "" : error}</label>
					<input className="submit-button" type="submit" value={`${isLoading ? "CHANGE..." : "CHANGE" }`} />
				</form>

				<img className="check-icon" src={Check} style={{display: isChanged ? "" : "none"}} />
				<p className="success" style={{display: isChanged ? "" : "none"}} >password successfully changed</p>

			</div>


			<Footer isSimilareItems={true}/>
			<CookieAlert/>
		</div>
	)
}

export default ResetPassword