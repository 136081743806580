import React, {useEffect, useState} from "react"
import "./Payout.css"
import HeaderFooter from "../HeaderFooter"
import CookieAlert from "../PUBLIC/CookieAlert"
import Footer from "../Footer"
import NavigationBar from "../NavigationBar"
import LoadingSpinner from "../MORE/LoadingSpinner"

function Payout(props) {
	const [isDesktopNav, setIsDesktopNav] = useState(window.innerWidth > 1200 ? true : false) // 1290 normal

	// Meessage
	const [message, setMessage] = useState("SENDING YOUR MONEY")
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		document.title = "Infinit Tag | Payout"

		// Query string
		const search = window.location.search
		const params = new URLSearchParams(search)
		const token = params.get('code')

		// User token in cookie
		let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)payoutToken\s*\=\s*([^;]*).*$)|^.*$/, "$1");

		if (token === null || token === undefined) {
			setMessage("Something went wrong, try again later.")
			setIsLoading(false)
		}

		fetchData(token, cookieValue)
	}, [])


	// Event Listener
	useEffect(() => {
		window.addEventListener("resize", handleWindowSize) 
		return () => window.removeEventListener("resize", handleWindowSize)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDesktopNav])

	const handleWindowSize = () => {
		if (window.innerWidth > 1200) {
			if (!isDesktopNav) setIsDesktopNav(true)
		} else {
			if (isDesktopNav) setIsDesktopNav(false)
		}
	} 


	// Fetch 
	const fetchData = async (paypalToken, userToken) => {
		let fetchData = { 
			headers: {
				'auth-token': userToken,
				'authorization_code': paypalToken
			}
		}

		// const data = await fetch("http://localhost:3000/api/paypal/payout", fetchData)
		let jsonData
		try {
			const data = await fetch("https://infinit-tag-api.herokuapp.com/api/paypal/payout", fetchData)
			jsonData = await data.json()
		} catch (err) {
			setMessage("Something went wrong, try again later.")
			setIsLoading(false)
		}

		if (jsonData.message !== "ok") {
			setMessage("Something went wrong, try again later.")
		} else {
			setMessage("Your money was successfully sent.")
		}
		setIsLoading(false)
	}




	return(
		<div className="Payout">
			{/* {isDesktopNav ? <HeaderFooter data={{actAn: true}}/> : <NavigationBar states={{isInCollection: false} }/>} */}

			<div className="loading-outer-container">
				<div className="loading-container">
					{ isLoading ? <LoadingSpinner /> : ""}
					<p>{message}</p>
				</div>
			</div>

			{/* {isDesktopNav ? "" : <Footer isSimilareItems={true}/>}
			<CookieAlert/> */}
		</div>
	)
}

export default Payout