import React from 'react'
import { motion } from "framer-motion"

const containereStyle = {
	position: "relative",
	width: "50px",
	height: "50px"
}

const circleStyle = {
	display: "block",
	width: "45px",
	height: "45px",
	border: "2px solid rgba(0, 0, 0, 0)",
	borderTop: "2px solid #282828",
	borderRadius: "50%",
	position: "absolute",
	boxSizing: "border-box",
	top: 0,
	left: 0
}

const spinTransition = {
	loop: Infinity,
	duration: "1",
	ease: "linear"
}


export default function LoadingSpinner() {
	return (
		<div style={containereStyle}>
			<motion.span style={circleStyle} animate={{ rotate: 360 }} transition={spinTransition} />
		</div>
	)
}