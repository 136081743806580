import React, { useState, useEffect, createRef} from 'react'
import "./StylePresentation.css"
import Share from "./Share"
import Cards from "./CardsV2"
import MainImage from "./MainImage"
import HeaderFooter from "../HeaderFooter"
import NavigationBar from "../NavigationBar"
import Footer from "../Footer"
import CookieAlert from "../PUBLIC/CookieAlert"


const scrollToTop = () => {
	window.scrollTo(0, 0) 
}

function StylePresentation(props) {

  // Json Data
  const [style, setStyle] = useState(props.location.state ? props.location.state.outfit : {})
  const [clothings, setClothings] = useState([])
  const [isJsonLoading, setIsJsonLoading] = useState(false)
  const [isFetchErr, setIsFetchErr] = useState(false)

  // Cards
  const [isHilighted, setIsHilighted] = useState([])
  const [stocklistIndex, setStocklistIndex] = useState([])
  const [isStockOpenArr, setIsStockOpenArr] = useState([])

  // Share
  const [isShareOpen, setIsShareOpen] = useState(false)

  // Nav Bar
  const [isDesktopNav, setIsDesktopNav] = useState(0)

  // Scroll to Card - Doc: https://www.robinwieruch.de/react-scroll-to-item/
  let refI = 0 
  const refs = clothings.reduce((acc, value) => {
    acc[refI] = createRef()
    refI += 1
    return acc
  }, {})



  useEffect(() => {
    scrollToTop()

    if (props.match.params.gender !== "men" && props.match.params.gender !== "women") {
      // console.log("404 page not found") 
      // props.history.push("/women");
    }

    document.title = "Outfit by  - Infinit Tag"

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  // EventListener
  useEffect(() => {
    if (isDesktopNav === 0) {
      if (window.innerWidth > 1290) setIsDesktopNav(true)
      else setIsDesktopNav(false)
    }

    window.addEventListener("resize", handleWindowSize) 
    return () => window.removeEventListener("resize", handleWindowSize)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktopNav])



  // fetch Json
  useEffect(() => {
    if (Object.entries(style).length === 0 && style.constructor === Object) {
      fetchItems()
    } else { setJsonData(style) }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [style]) 



  // Fetch Json
  const fetchItems = async () => {
    // const data = await fetch(`/Fashion_API/Fashion_${props}_Json.json`)
    setIsJsonLoading(true)

    let items
    try {
      const data = await fetch(`https://infinit-tag-api.herokuapp.com/api/user-outfits/user/outfitId=${props.match.params.outfitId}`)
      // const data = await fetch(`http://localhost:3000/api/user-outfits/user/outfitId=${props.match.params.outfitId}`)
      items = await data.json()
      if (items.length >= 0) setJsonData(items[0])
      else setIsFetchErr(true)

    } catch (err) {
      console.log(`Fetch error: ${err}`)
      setIsFetchErr(true)
      setIsJsonLoading(false)
      return
    }

    setIsJsonLoading(false)
  }


  const setJsonData = (data) => {
    setStyle(data)
    setClothings(data.clothings)
    setIsHilighted(data.clothings.map(() => false))
    setIsStockOpenArr(data.clothings.map(() => false))
    setStocklistIndex(data.clothings.map(() => 0))
  }



 
  // Event Listener window Size
  const handleWindowSize = () => {
    if (window.innerWidth > 1290) {
      if (!isDesktopNav) setIsDesktopNav(true)
    } else {
      if (isDesktopNav) setIsDesktopNav(false)
    }
  }

  // SHARE
	// Share Click
	// const onShareClick = (target) => { setIsShareOpen(target) }
  

  // Share Callback
  const shareCallback = (shareIsOpenData) => {
    setIsShareOpen(shareIsOpenData)
  }

  const shareData = {
    isShareOpen: isShareOpen,
    shareCallback: shareCallback,
    styleId: props.match.params.id,
    gender: props.match.params.gender
  }



  // CARDS
  // Callback
  const setStocklistCallback = (target) => {
    setStocklistIndex(target)
  }

  const setStockOpenCallback = (target) => {
    setIsStockOpenArr(target)
  }

  const cardsData = {
    refs: refs,
    clothings: clothings,
    outfit: style,
    isHilighted: isHilighted,
    isStockOpenArr: isStockOpenArr,
    stocklistIndex: stocklistIndex,
    setIsStockOpenArr: setStockOpenCallback,
    setStocklistIndex: setStocklistCallback,
    pathInfo: props.match.params
  }


  // Main Image Callback
  const setHighlitedCallback = (target) => { setIsHilighted(target) }

  // Share CallBack
  const onShareCallack = (target) => { setIsShareOpen(target) }

  const mainImageData = {
    refs: refs,
    clothings: clothings,
    isHilighted: isHilighted,
    setIsHilighted: setHighlitedCallback,
    style: style,
    styleId: props.match.params.id,
    onShareCallack: onShareCallack,
    gender: props.match.params.gender
  }

  const navigationStates = {
    isInCollection: false, 
    gender: props.match.params.gender,
    onShareCallack: onShareCallack,
    shouldDiaplayShare: true
  }


  return (
    <div className="preview">
      {isDesktopNav ? <HeaderFooter data={{actAn: true}}/> : <NavigationBar states={navigationStates}/>}

      <div className="loadingAlert" style={{display: isJsonLoading || isFetchErr ? "" : "none"}}>
          <p> {isFetchErr ? "SOMETHING WENT WRONG, TRY AGAIN LATER." : isJsonLoading ? "LOADING..." : ""} </p>
      </div>

      {/* Content */}
      <div className="preview-container" style={{display: isJsonLoading || isFetchErr ? "none" : "flex"}}>
        {<MainImage data={mainImageData}/>}
        {<Cards data={cardsData}/>}
      </div>

      <Share data={shareData}/>

      {isDesktopNav ? "" : <Footer isSimilareItems={false}/>}

      <CookieAlert/>
    </div> 
  )
}

export default StylePresentation
