import React, {useEffect, useState} from "react"
import LoadingSpinner from "../MORE/LoadingSpinner"
import "./RedirectPaypal.css"

function Payout(props) {
	const [isDesktopNav, setIsDesktopNav] = useState(window.innerWidth > 1200 ? true : false) // 1290 normal

	// Meessage
	const [message, setMessage] = useState("REDIRECT TO PAYPAL")
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		document.title = "Infinit Tag | Redirect"

		const search = window.location.search
		const params = new URLSearchParams(search)
		const token = params.get('token')

		if (token === undefined || token === null) { 
			setMessage("Something went wrong, try again later.")
			setIsLoading(false)
		}

		document.cookie = `payoutToken=${token}; SameSite=Lax`

		// Redirect to Paypal login
		const redirectUrl = encodeURIComponent("https://infinittag.de/payout/paypal/last-step/")
		const scopeAttributes = encodeURIComponent("profile email https://uri.paypal.com/services/paypalattributes")
		const urlString = `https://www.sandbox.paypal.com/connect?flowEntry=static&client_id=AUNrRxDClQc6k6TT1YQMgn7t6rJzzAvbDoqLXr6Rzc8oUUpOONgRZxnUsimOyEBKhfd9r_BQE-SQFslF&scope=${scopeAttributes}&redirect_uri=${redirectUrl}`
		window.location.replace(urlString);
	}, [])

	// Event Listener
	useEffect(() => {
		window.addEventListener("resize", handleWindowSize) 
		return () => window.removeEventListener("resize", handleWindowSize)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDesktopNav])

	const handleWindowSize = () => {
		if (window.innerWidth > 1200) {
			if (!isDesktopNav) setIsDesktopNav(true)
		} else {
			if (isDesktopNav) setIsDesktopNav(false)
		}
	} 

	return(
		<div className="RedirectPaypal">
			<div className="loading-container">
				{ isLoading ? <LoadingSpinner /> : ""}
				<p>{message}</p>
			</div>
		</div>
	)
}

export default Payout